import React from "react";
import * as app from "../fetch/fetchApi";

const BannerMensaje = () => {
  var d1 = new Date();
  var d2 = new Date("2025-01-31T00:00:00");
  // console.log(d1.getHours());
  var show = d1.getTime() < d2.getTime();
  
  return (
  //   <div class="notice" style={{ position: 'relative', zIndex: '9999', background: 'orange', textAlign: 'center', fontWeight: '500', color: 'white', fontSize: ' .85em', padding: '3px 0' }}>
  //     <a style={{ color: 'white', fontWeight: 'bold' }}>Del 19 sept. al 26 de oct., la alberca general estará cerrada por mantenimiento.</a>
  // </div >
    show && <div class="notice" style={{ position: 'relative', zIndex: '9999', background: 'orange', textAlign: 'center', fontWeight: '500', color: 'white', fontSize: ' .85em', padding: '3px 0' }}>
      <a style={{ color: 'white', fontWeight: 'bold' }}>Del 26 de agosto al 30 de enero, el acceso de la taquilla al interior de Las Estacas será por un camino peatonal o carritos debido a la remodelación del puente principal.</a>
  </div >
  );
};

export default BannerMensaje;